::-webkit-media-controls {
    display: none !important;
  }
  .video-container {
    display: flex;
    border-radius: 4px;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
  }
  
  .video-container:hover .video-controls,
  video:hover + .video-controls {
    display: block;
  }
  
  video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  
  .video-controls {
    right: 0;
    left: 0;
    padding: 10px;
    position: absolute;
    bottom: 0;
    transition: all 0.2s ease;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5)
    );
  }
  .video-controls.hide {
    opacity: 0;
    pointer-events: none;
  }
  
  .video-progress {
    position: relative;
    height: 8.4px;
    margin-bottom: 10px;
  }
  
  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    width: 100%;
    height: 8.4px;
    pointer-events: none;
    position: absolute;
    top: 0;
  }
  
  progress::-webkit-progress-bar {
    background-color: #474545;
    border-radius: 2px;
  }
  
  progress::-webkit-progress-value {
    background: var(--youtube-red);
    border-radius: 2px;
  }
  
  progress::-moz-progress-bar {
    border: 1px solid var(--youtube-red);
    background: var(--youtube-red);
  }
  
  .seek {
    position: absolute;
    top: 0;
    width: 100%;
    cursor: pointer;
    margin: 0;
  }
  
  .seek:hover + .seek-tooltip {
    display: block;
  }
  
  .seek-tooltip {
    display: none;
    position: absolute;
    top: -50px;
    margin-left: -20px;
    font-size: 12px;
    padding: 3px;
    content: attr(data-title);
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .bottom-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-controls {
    display: flex;
    align-items: center;
    color: #fff;
  }
  
  .volume-controls {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .volume-controls input {
    width: 100px;
    opacity: 1;
    transition: all 0.4s ease;
  }
  
  .volume-controls:hover input,
  .volume-controls input:focus {
    width: 100px;
    opacity: 1;
  }
  
  button {
    cursor: pointer;
    position: relative;
    margin-right: 7px;
    font-size: 12px;
    padding: 3px;
    border: none;
    outline: none;
    background-color: transparent;
  }
  
  button * {
    pointer-events: none;
  }
  
  button::before {
    content: attr(data-title);
    position: absolute;
    display: none;
    right: 0;
    top: -50px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-weight: bold;
    padding: 4px 6px;
    word-break: keep-all;
    white-space: pre;
  }
  
  button:hover::before {
    display: inline-block;
  }
  
  .fullscreen-button {
    margin-right: 0;
  }
  
  .pip-button svg {
    width: 26px;
    height: 26px;
  }
  
  .playback-animation {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }
  
  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 8.4px;
    background: transparent;
    cursor: pointer;
  }
  
  input[type='range']:focus {
    outline: none;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 1.3px;
    -webkit-appearance: none;
    transition: all 0.4s ease;
  }
  
  input[type='range']::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: var(--youtube-red);
    cursor: pointer;
    -webkit-appearance: none;
    margin-left: -1px;
  }
  
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
  }
  
  input[type='range'].volume {
    height: 5px;
    width: 100%;
    background-color: #fff;
  }
  
  input[type='range'].volume::-webkit-slider-runnable-track {
    background-color: transparent;
  }
  
  input[type='range'].volume::-webkit-slider-thumb {
    margin-left: 0;
    height: 14px;
    width: 14px;
    background: #fff;
  }
  
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 1.3px;
  }
  
  input[type='range']::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50px;
    border: 1px solid var(--youtube-red);
    background: var(--youtube-red);
    cursor: pointer;
    margin-top: 5px;
  }
  
  input[type='range']:focus::-moz-range-track {
    outline: none;
  }
  
  input[type='range'].volume::-moz-range-thumb {
    border: 1px solid #fff;
    background: #fff;
  }
  
  .hidden {
    display: none;
  }
  
  svg {
    width: 28px;
    height: 28px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
  }
  